<template>
  <div class="margin-fix">
    <div class="md-list-item-text space-down">
      <span class="md-display-1 title-fix">Inicio</span>
      <span class="md-caption top">Resumen de valores de catálogo</span>
    </div>
  </div>
</template>

<script>
import auth from '@/mixins/auth'
export default {
  name: 'Home',
  mixins: [auth]
}
</script>
